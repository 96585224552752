






























































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import API from "@/api";
import { mapState } from "vuex";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import { Project } from "@/models/Project.ts";

@Component({
  metaInfo: {
    // @ts-ignore
    title: "Gracias por invertir",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState([
      "user",
      "currentInvestmentId",
      "isLastInvestment",
      "gatewayTransaction",
      "transactionIsPendingPayment",
      "lastCod"
    ]),
    timeLimitText() {
      if (this.$data.investment != null) {
        if (this.$data.investment.proyecto.credito_tipo.id == 1) {
          return this.$data.investment.proyecto.cuotas == 1
            ? `${this.$data.investment.proyecto.cuotas} Dia`
            : `${this.$data.investment.proyecto.cuotas} Días`;
        } else {
          return this.$data.investment.proyecto.cuotas == 1
            ? `${this.$data.investment.proyecto.cuotas} Mes`
            : `${this.$data.investment.proyecto.cuotas} Meses`;
        }
      }
      if (this.$data.detail != null) {
        if (this.$data.detail.proyecto.credito_tipo.id == 1) {
          return this.$data.detail.proyecto.cuotas == 1
            ? `${this.$data.detail.proyecto.cuotas} Dia`
            : `${this.$data.detail.proyecto.cuotas} Días`;
        } else {
          return this.$data.detail.proyecto.cuotas == 1
            ? `${this.$data.detail.proyecto.cuotas} Mes`
            : `${this.$data.detail.proyecto.cuotas} Meses`;
        }
      }
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      investment: null,
      project: null,
      detail: null,
      canShow: false,
      simulation: null,
      transaction: null,
      cuotasHeaders: [
        {
          text: "Mes",
          value: "inv_mes"
        },
        {
          text: "Cuota",
          value: "inv_cuota"
        },
        {
          text: "Fecha",
          value: "inv_fecha"
        }
      ]
    };
  },
  async beforeMount() {
    if (this.$store.state.gatewayTransaction.pasarela_id == 1) {
      if (this.$store.state.currentInvestmentId != null) {
        this.$data.investment = await API.investment.normal(
          this.$store.state.currentInvestmentId
        );
        this.$data.investment.derechos =  parseFloat(this.$data.investment.derechos)
 
        this.$data.simulation = await API.simulate(
          this.$store.state.proyectId,
          this.$data.investment.ultima_inversion == 0
            ? this.$data.investment.monto_a_invertir
            : this.$data.investment.monto_a_invertir +
                this.$data.investment.derechos,
          this.$store.state.isLastInvestment
        );
        this.$data.project = new Project(this.$data.investment.proyecto);
        this.$data.canShow = true;
      }
    } else {
      this.$store.commit("resetGatewayTransactionConfirmation");
      this.$data.transaction = this.$store.state.gatewayTransaction;
      this.$data.detail = await API.investment.normal(
        this.$store.state.gatewayTransaction.inversion_id
      );
      this.$data.simulation = await API.simulate(
        this.$store.state.proyectId,
        this.$data.detail.ultima_inversion == 0
          ? this.$data.detail.monto_a_invertir
          : this.$data.detail.monto_a_invertir +
              this.$data.detail.derechos,
        this.$store.state.isLastInvestment
      );
      this.$data.project = new Project(this.$data.detail.proyecto);
      if (this.$store.state.gatewayTransaction != null) {
        if (!this.$store.state.gatewayTransactionConfirmed) {
          if (this.$store.state.transactionIsPendingPayment) {
            API.khipu.confirm
              .pending(this.$data.transaction)
              .then((res: any) => {
                console.log(res);
                this.$store.commit("confirmGatewayTransaction");
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            API.khipu.confirm
              .normal(this.$data.transaction)
              .then(res => {
                console.log(res);
                this.$store.commit("confirmGatewayTransaction");
              })
              .catch(error => {
                console.log(error);
              });
          }
        }
      }
      this.$data.canShow = true;
    }
  },
  methods: {}
})
export default class ThanksForInvesting extends Vue {}
